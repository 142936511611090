import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import type { NotificationSessionKeyTypesEnum } from './types'

export interface NotificationSessionStorageState {
  home: boolean
  product: boolean
  support: boolean
  browserSupport: boolean
  getNotificationValue: (
    notificationName: NotificationSessionKeyTypesEnum,
  ) => boolean
  setNotificationValue: (notificationName: string, value: boolean) => void
}

const initialNotificationSessionStorageState = {
  home: true,
  product: true,
  support: true,
  browserSupport: true,
}

const notificationSessionStore = (set: any, get: any) => ({
  ...initialNotificationSessionStorageState,
  getNotificationValue: (notificationName: NotificationSessionKeyTypesEnum) => {
    return get()[notificationName]
  },
  setNotificationValue: (notificationName: string, value: boolean) => {
    set(() => {
      return { [notificationName]: value }
    })
  },
})

export const useSessionStorageNotificationStore =
  create<NotificationSessionStorageState>()(
    persist(notificationSessionStore, {
      name: 'notification-dismissals',
      storage: createJSONStorage(() => sessionStorage),
    }),
  )
