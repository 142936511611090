import { Feature, FeatureText } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'

import { Link, NextImage, PageAnchor } from '@/components'
import type { FeatureImageTextData } from '@/data/aem/features'
import { parseContent } from '@/lib/parse-content'

import { LabelFlagger } from '../label-flagger'

interface FeatureImageTextProps {
  featureData: Omit<FeatureImageTextData, 'featureFlag' | 'type' | 'id'>
  shouldHideFirstTopMargin?: boolean
  featureImageAlignment?: string
}

export const ImageTextFeature = ({
  featureData,
  shouldHideFirstTopMargin,
  featureImageAlignment,
}: FeatureImageTextProps) => {
  const { image, label, title, description, cta } = featureData

  return (
    <PageAnchor asChild idString={title}>
      <Feature.Root
        className={cn('tw-container', {
          'tw-flex-row-reverse': featureImageAlignment === 'right',
          'tw-pt-4 md:tw-pt-4 lg:tw-pt-4': shouldHideFirstTopMargin,
        })}
      >
        <Feature.ImageWrapper asChild>
          <NextImage
            src={image.data.src}
            width={640}
            height={640}
            alt={image.alt}
          />
        </Feature.ImageWrapper>
        <Feature.Content>
          <FeatureText.Root>
            {label && (
              <LabelFlagger>
                <FeatureText.Label>{label}</FeatureText.Label>
              </LabelFlagger>
            )}
            <FeatureText.Title>{title}</FeatureText.Title>
            {description && (
              <FeatureText.Content>
                {parseContent(description.__html__, {
                  replaceh4h6TagsWithSmallGreyText: true,
                })}
              </FeatureText.Content>
            )}
            {cta && cta.to && (
              <FeatureText.Cta asChild>
                <Link
                  href={cta.to}
                  sectionLabel="CtaItem"
                  trackingActionLabel={cta.label}
                >
                  {cta.label}
                </Link>
              </FeatureText.Cta>
            )}
          </FeatureText.Root>
        </Feature.Content>
      </Feature.Root>
    </PageAnchor>
  )
}
