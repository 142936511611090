/* eslint-disable @typescript-eslint/no-non-null-assertion */
import sdk from '@mparticle/web-sdk'

import type { CookieConsentsArgs } from '../../consents'
import { fetchProspectId } from '../../prospects'
import { getUtmParameters, redactedUrl } from '../../utmParameters'
import { REDACTED, redactPii } from '../../validators'
import type { InterfaceType, PageViewEvent } from '..'
import { analyticsConfig } from './config'
import {
  AnalyticsEventType,
  PartialScreenInterfaceType,
  STRICTLY_NECESSARY_EVENTS,
} from '../constants'
import type { MParticleDataLayer, MParticleReferrerAttributes } from '.'

export const ADOBE_UTM = 'Adobe.UTM'

export const dataLayer: MParticleDataLayer = {
  pageTitle: null,
  appVersionBuild: null,
  environmentName: null,
  referrerUrl: null,
  referrerDomain: null,
  utmAttributes: null,
  partialScreenName: null,
}

export const resetDataLayer = () => {
  dataLayer.pageTitle = null
  dataLayer.appVersionBuild = null
  dataLayer.environmentName = null
  dataLayer.referrerUrl = null
  dataLayer.referrerDomain = null
  dataLayer.utmAttributes = null
  dataLayer.partialScreenName = null
}

const getPagePath = (url: URL) => url.toString().split(/[?#]/)[0]

let initialized = false

export const isInitialized = () => initialized

export const initSdk = (callback?: () => void) => {
  const { environment, webProspectId, dataPlanId, dataPlanVersion, apiKey } =
    analyticsConfig.getMParticleConfig()!

  const identityCallback = (result: any) => {
    const user = result.getUser()
    if (user) {
      const webProspectId = fetchProspectId()
      user.setUserAttribute('webProspectId', webProspectId)
      initialized = true
      callback && callback()
    } else {
      // the IDSync call failed - see below for more details on failed requests
    }
  }

  const config = {
    isDevelopmentMode: environment !== 'production',
    v1SecureServiceUrl: 'www.chase.co.uk/mparticle/events/v1/',
    v2SecureServiceUrl: 'www.chase.co.uk/mparticle/events/v2/',
    v3SecureServiceUrl: 'www.chase.co.uk/mparticle/events/v3/',
    configUrl: 'www.chase.co.uk/mparticle/config/',
    identityUrl: 'www.chase.co.uk/mparticle/identity/v1/',
    aliasUrl: 'www.chase.co.uk/mparticle/v1/identity/',
    identifyRequest: {
      userIdentities: {
        other2: webProspectId,
      },
    },
    dataPlan: { planId: dataPlanId, planVersion: dataPlanVersion },
    identityCallback,
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('@mparticle/web-adobe-server-kit').register(config)
  sdk.init(apiKey, config)
}

const objectExcludingAttribute = (
  original: Record<string, string>,
  attributeToExclude: string,
): Record<string, string> =>
  Object.keys(original).reduce((partial, currentAttribute) => {
    const shouldExcludeCurrentAttribute =
      currentAttribute === attributeToExclude
    return shouldExcludeCurrentAttribute
      ? partial
      : {
          ...partial,
          ...{ [currentAttribute]: original[currentAttribute] },
        }
  }, {})

const adobeUtmAttributes = (utmAttributes: Record<string, string>) => {
  const params = new URLSearchParams()
  Object.entries(objectExcludingAttribute(utmAttributes, ADOBE_UTM)).forEach(
    ([key, value]) => {
      params.append(key, value)
    },
  )
  return params
}

const redactedObject = (original: Record<string, string>) =>
  Object.keys(original).reduce(
    (redacted, attribute) => ({
      ...redacted,
      ...{ [attribute]: redactPii(original[attribute]) },
    }),
    {},
  )

const getUtmAttributes = async () => {
  const attributes = redactedObject({
    ...((await getUtmParameters()) || {}),
    ...(dataLayer.utmAttributes ?? {}),
  })
  const attributesFound = Object.keys(attributes).length > 0

  return attributesFound
    ? {
        ...attributes,
        [ADOBE_UTM]: adobeUtmAttributes(attributes).toString(),
      }
    : {}
}

const getReferrerAttributes = (): MParticleReferrerAttributes | null => {
  const referrerUrl = document.referrer

  if (referrerUrl) {
    const referrerDomain = new URL(referrerUrl).hostname

    return {
      referrerUrl,
      referrerDomain,
      'Adobe.Referrer': referrerUrl,
    }
  }

  return null
}

export const trackEvent = async ({
  mpEventName,
  type,
  eventName,
  interfaceType,
  pageSectionName,
  partialScreenTitle,
  backgroundPageTitle,
}: {
  mpEventName: AnalyticsEventType
  type: AnalyticsEventType
  eventName: string
  interfaceType: InterfaceType
  pageSectionName?: string
  partialScreenTitle?: string
  backgroundPageTitle?: string
}) => {
  if (backgroundPageTitle) {
    const environmentConfig = analyticsConfig.getEnvironment()
    dataLayer.environmentName = environmentConfig!.environmentName
    dataLayer.appVersionBuild = environmentConfig!.appVersionBuild
    dataLayer.pageTitle = backgroundPageTitle
  }
  if (partialScreenTitle) {
    dataLayer.partialScreenName = partialScreenTitle
  }
  const { pageTitle, partialScreenName, environmentName, appVersionBuild } =
    dataLayer
  const customAttributes: any = {
    pageUrl: redactedUrl().toString(),
    type,
    eventName,
    screenName: pageTitle,
    parent:
      pageSectionName &&
      Object.values<string>(PartialScreenInterfaceType).includes(
        pageSectionName,
      )
        ? partialScreenName
        : pageTitle,
    interfaceType,
    environmentName,
    appVersionBuild,
  }
  if (pageSectionName) {
    customAttributes.pageSectionName = pageSectionName
  }
  sdk.logEvent(mpEventName, sdk.EventType.Other, customAttributes)
}

export const trackPageView = async (event: PageViewEvent) => {
  const { pageTitle } = event
  const { environmentName, appVersionBuild } = analyticsConfig.getEnvironment()!
  const url = redactedUrl()
  const utmAttributes = await getUtmAttributes()
  const referrerAttributes = getReferrerAttributes()
  const urlFragment = redactPii(url.hash.replace('#', ''))

  dataLayer.pageTitle = pageTitle
  dataLayer.environmentName = environmentName
  dataLayer.appVersionBuild = appVersionBuild
  dataLayer.utmAttributes = utmAttributes

  if (referrerAttributes) {
    dataLayer.referrerUrl = referrerAttributes.referrerUrl
    dataLayer.referrerDomain = referrerAttributes.referrerDomain
  }

  const customAttributes: any = {
    eventName: pageTitle,
    screenName: pageTitle,
    type: AnalyticsEventType.SCREEN,
    interfaceType: AnalyticsEventType.SCREEN,
    'pageUrl.path': getPagePath(url),
    'pageUrl.query': url.searchParams.toString(),
    pageUrl: url.toString(),
    appVersionBuild,
    environmentName,
    ...referrerAttributes,
    ...utmAttributes,
  }

  if (urlFragment.trim().length > 0) {
    customAttributes['pageUrl.fragment'] =
      urlFragment !== REDACTED ? `#${urlFragment}` : urlFragment
  }

  sdk.logPageView('Web Page View', customAttributes)
}

export const isTrackableWithoutConsent = (event: any): boolean => {
  return (STRICTLY_NECESSARY_EVENTS as any).some((rule: any) =>
    Object.keys(rule).every((key) => rule[key] === event[key]),
  )
}

export const setConsents = (consents: CookieConsentsArgs) => {
  const consentState = sdk.Consent?.createConsentState()
  const user = sdk.Identity?.getCurrentUser()

  if (consentState && user) {
    consentState.addGDPRConsentState(
      'Web Strictly Necessary',
      sdk.Consent.createGDPRConsent(
        consents.StrictlyNecessary,
        Date.now(),
        '',
        '',
        '',
      ),
    )
    consentState.addGDPRConsentState(
      'Web Analytical',
      sdk.Consent.createGDPRConsent(
        consents.Analytical,
        Date.now(),
        '',
        '',
        '',
      ),
    )
    consentState.addGDPRConsentState(
      'Web Performance',
      sdk.Consent.createGDPRConsent(
        consents.Performance,
        Date.now(),
        '',
        '',
        '',
      ),
    )
    consentState.addGDPRConsentState(
      'Web Advertising and targeting',
      sdk.Consent.createGDPRConsent(
        consents.AdvertisingAndTargeting,
        Date.now(),
        '',
        '',
        '',
      ),
    )

    user.setConsentState(consentState)

    sdk.logEvent('GDPR Consent Changed', sdk.EventType.UserPreference)
  }
}
