'use client'
import { getMarketingChannel, useSplitStore } from '@chaseweb/utils/split'
import {
  analytics,
  captureUtmParameters,
  fetchProspectId,
  MarketingPixelsFragment,
} from '@chaseweb/utils/tracking'
import { useEffect, useState } from 'react'

import { useChaseEnv, usePageTitle } from '@/lib/hooks'
import { useConsentStore } from '@/lib/stores'

export const Insights = () => {
  const env = useChaseEnv()
  const split = useSplitStore()
  const marketingChannel = getMarketingChannel()
  const [webProspectId, setWebProspectId] = useState<string | null>(null)

  const consentInit = useConsentStore((store) => store.init)
  const consents = useConsentStore((store) => store.consents)
  const consentsCaptured = useConsentStore((store) => store.captured)

  useEffect(() => {
    captureUtmParameters()
  }, [])

  useEffect(() => {
    setWebProspectId(fetchProspectId())
  }, [setWebProspectId])

  useEffect(() => {
    consentInit()
  }, [consentInit])

  const pageTitle = usePageTitle()
  useEffect(() => {
    if (pageTitle) {
      analytics.trackPageView({ pageTitle })
    }
  }, [pageTitle])

  useEffect(() => {
    if (!env) return
    const {
      environment,
      buildVersion,
      mParticlePlanId,
      mParticlePlanVersion,
      mParticleApiKey,
    } = env

    if (
      webProspectId &&
      environment &&
      mParticlePlanId &&
      mParticleApiKey &&
      mParticlePlanVersion
    ) {
      analytics.init({
        environment: {
          environmentName: environment,
          appVersionBuild: buildVersion ?? 'dev',
        },
        mParticleConfig: {
          environment,
          apiKey: mParticleApiKey,
          webProspectId,
          dataPlanId: mParticlePlanId,
          dataPlanVersion: mParticlePlanVersion,
        },
      })
    }
  }, [env, webProspectId])

  useEffect(() => {
    if (consentsCaptured) {
      analytics.setConsents(consents)
    }
  }, [consents, consentsCaptured])

  useEffect(() => {
    if (env && webProspectId) {
      const { splitApiKey, splitProxyUrl } = env
      split.init({
        apiKey: splitApiKey,
        proxyUrl: splitProxyUrl ?? null,
        webProspectId,
        marketingChannel,
        consentsCaptured: consentsCaptured,
        hasConsentToSendImpressions: consents.Analytical,
      })
    }
  }, [split, marketingChannel, webProspectId, env, consents, consentsCaptured])

  useEffect(() => {
    if (split && consentsCaptured) {
      split.updateCookieConsents({
        consentsCaptured,
        hasConsentToSendImpressions: consents.Analytical,
      })
    }
  }, [split, consents, consentsCaptured])

  return (
    <MarketingPixelsFragment
      hasAdTargetingConsent={consents.AdvertisingAndTargeting}
    />
  )
}
