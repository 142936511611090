import { InfoBlock, SVG } from '@chaseweb/ui-library/src'

import { Link } from '../link'

export const StatusBlock = () => {
  return (
    <InfoBlock.Root variant={'secondary'}>
      <InfoBlock.ImageWrapper>
        <SVG name={'InformationBlueRing'} />
      </InfoBlock.ImageWrapper>
      <InfoBlock.Content>
        <InfoBlock.Title>Current system status</InfoBlock.Title>
        <InfoBlock.Description>
          We maintain and at times update all our services. You can check on the
          status of our services any time you like.
        </InfoBlock.Description>
        <InfoBlock.Cta asChild role="link">
          <Link
            trackingActionLabel={'View status'}
            sectionLabel="CtaItem"
            href="https://chaseuk.statuspage.io/"
            target="_blank"
          >
            View status
          </Link>
        </InfoBlock.Cta>
      </InfoBlock.Content>
    </InfoBlock.Root>
  )
}
