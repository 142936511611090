export enum NotificationSessionKeyTypesEnum {
  HOME = 'home',
  PRODUCT = 'product',
  SUPPORT = 'support',
  BROWSER = 'browserSupport',
}

export enum NotificationLocalKeyTypesEnum {
  US_REDIRECT = 'usRedirect',
}

export const NotificationKeyTypesEnum = {
  ...NotificationSessionKeyTypesEnum,
  ...NotificationLocalKeyTypesEnum,
}
