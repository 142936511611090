/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import {
  Accordion,
  Card,
  CardGrid,
  CardProfile,
  Carousel,
  EditorialCard,
  FeatureText,
  FullWidthFeature,
  Heading,
  ImageCard,
  InfoBlock,
  LogoTile,
  LogoTileGrid,
  Prose,
  SummaryList,
  SVG,
} from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import type { ReactElement } from 'react'
import { UnreachableCaseError } from 'ts-essentials'

import {
  FaqAccordion,
  Link,
  MultiSizeImg,
  NextImage,
  PageAnchor,
  Promo,
  RichText,
} from '@/components'
import type { MainPage } from '@/data/aem'
import { parseContent } from '@/lib/parse-content'
import type { RelativePathOrHrefExternalType } from '@/types'

import { AvailabilityAndPerformanceReport } from '../availability-and-performance-report'
import { ImageTextFeature } from './feature-components/image-text-feature'
import { FeatureFlagger } from './feature-flagger'

interface FeatureListProps {
  featureList: MainPage['featureList']
  isHomepage?: boolean
  hideFirstTopMargin?: boolean
}

export const FeatureList = ({
  featureList,
  isHomepage,
  hideFirstTopMargin,
}: FeatureListProps) => {
  /**
   * if the image-text-feature is the first feature on the page, it should have image on the left.
   * each image-text-feature then alternates image-right, image-left etc.
   * for new sequences of image-text-feature on the same page, the first one should have image on the right.
   * HERO
   * IMAGE TEXT
   * TEXT IMAGE
   * IMAGE TEXT etc.
   * SOME OTHER FEATURE
   * SOME OTHER FEATURE
   * TEXT IMAGE
   * IMAGE TEXT etc.
   */
  const featureTypes = featureList.map((feat) => feat.type)
  const featureImageAlignment: ('left' | 'right')[] = []
  for (let i = 0; i < featureTypes.length; i++) {
    if (featureTypes[i] === 'image-text-feature') {
      if (i === 0) {
        featureImageAlignment[i] = 'left'
      } else {
        featureImageAlignment[i] =
          featureImageAlignment[i - 1] === 'right' ? 'left' : 'right'
      }
    }
  }

  return featureList.map((feature, index) => {
    let component: ReactElement
    const shouldHideFirstTopMargin = hideFirstTopMargin && index === 0
    if (feature.type === 'rates-feature') {
      component = (
        <PageAnchor
          idString={feature.title}
          className={cn('tw-container tw-mt-24', {
            'tw-mt-4': shouldHideFirstTopMargin,
          })}
        >
          <div className="tw-flex tw-flex-col tw-gap-6 tw-p-6 lg:tw-gap-8">
            <SummaryList.Root>
              <SummaryList.Card>
                <SummaryList.ImageWrapper asChild>
                  <NextImage
                    alt=""
                    src={feature.image.src}
                    width={200}
                    height={200}
                  />
                </SummaryList.ImageWrapper>
                <SummaryList.Title>{feature.title}</SummaryList.Title>
                <SummaryList.Content>
                  <SummaryList.Wrapper>
                    {feature.rates.map(({ label, value, description }) => (
                      <SummaryList.Item key={value}>
                        <SummaryList.Label>{label}</SummaryList.Label>
                        <SummaryList.TextWrapper>
                          <SummaryList.Value>{value}</SummaryList.Value>
                          {description && (
                            <SummaryList.Description aria-hidden>
                              {description}
                            </SummaryList.Description>
                          )}
                        </SummaryList.TextWrapper>
                      </SummaryList.Item>
                    ))}
                  </SummaryList.Wrapper>
                </SummaryList.Content>
              </SummaryList.Card>
              {feature.termsAndConditions && (
                <SummaryList.Disclaimer>
                  <RichText className="tw-text-sm">
                    {feature.termsAndConditions}
                  </RichText>
                </SummaryList.Disclaimer>
              )}
            </SummaryList.Root>
          </div>
        </PageAnchor>
      )
    } else if (feature.type === 'info-block-feature') {
      component = (
        <PageAnchor
          idString={feature.title}
          className={cn('tw-container tw-mt-24', {
            'tw-mt-4': shouldHideFirstTopMargin,
          })}
        >
          <InfoBlock.Root variant={feature.variant} className="tw-mb-16">
            <InfoBlock.ImageWrapper>
              <SVG name="InformationBlueRing" />
            </InfoBlock.ImageWrapper>
            <InfoBlock.Content>
              <InfoBlock.Title>{feature.title}</InfoBlock.Title>
              <InfoBlock.Description>
                {feature.description}
              </InfoBlock.Description>
            </InfoBlock.Content>
          </InfoBlock.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'step-card-grid-feature') {
      component = (
        <PageAnchor
          idString={feature.title}
          className={cn('tw-bg-blue90 tw-pb-18 tw-pt-24', {
            'tw-pt-4': shouldHideFirstTopMargin,
          })}
        >
          <div className="tw-container">
            <Heading type="h2">{feature.title}</Heading>
            {feature.description && (
              <p className="tw-mt-4">{feature.description}</p>
            )}
            <div className="tw-mt-12">
              <CardGrid.Root variant="instruction">
                {feature.cards.map(({ title, description, image }) => (
                  <Card.Root key={`${title}${description}`}>
                    <Card.ImageWrapper>
                      <NextImage
                        width="700"
                        height="700"
                        src={image.src}
                        alt=""
                      />
                    </Card.ImageWrapper>
                    <Card.Content>
                      <Card.Title>{title}</Card.Title>
                      <Card.Description>
                        <RichText>{description}</RichText>
                      </Card.Description>
                    </Card.Content>
                  </Card.Root>
                ))}
              </CardGrid.Root>
            </div>
          </div>
        </PageAnchor>
      )
    } else if (feature.type === 'image-card-grid-feature') {
      component = (
        <PageAnchor
          idString={feature.title}
          className={cn('tw-container tw-mt-16', {
            'tw-mt-4': shouldHideFirstTopMargin,
          })}
        >
          <Heading type="h2">{feature.title}</Heading>
          {feature.description && (
            <p className="tw-mt-4">{feature.description}</p>
          )}
          <CardGrid.Root className="tw-mt-10 lg:tw-mt-14" variant="image">
            {feature.cards.map(({ image, title, ctaLink, description }) => {
              const cardContent = (
                <>
                  <ImageCard.ImageWrapper>
                    <NextImage fill src={image.src} alt="" />
                  </ImageCard.ImageWrapper>
                  <ImageCard.Content>
                    <ImageCard.Title>{title}</ImageCard.Title>
                    <ImageCard.Description>{description}</ImageCard.Description>
                  </ImageCard.Content>
                </>
              )
              if (ctaLink) {
                return (
                  <ImageCard.Root
                    asChild
                    key={`${title}${description}`}
                    isClickable
                  >
                    <Link
                      href={ctaLink}
                      aria-label={`open card ${title}`}
                      sectionLabel="Card CtaItem"
                      trackingActionLabel={title}
                    >
                      {cardContent}
                    </Link>
                  </ImageCard.Root>
                )
              }
              return (
                <ImageCard.Root key={`${title}${description}`}>
                  {cardContent}
                </ImageCard.Root>
              )
            })}
          </CardGrid.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'product-card-grid-feature') {
      component = (
        <PageAnchor
          idString={feature.title}
          className={cn('tw-container tw-mt-24', {
            'tw-mt-4': shouldHideFirstTopMargin,
          })}
        >
          <Heading type="h2">{feature.title}</Heading>
          {feature.description && (
            <Prose className="tw-mt-8">
              {parseContent(feature.description.__html__, {
                replaceh4h6TagsWithSmallGreyText: true,
              })}
            </Prose>
          )}
          <CardGrid.Root variant="product" className="tw-pb-16 tw-pt-12">
            {feature.cards.map(
              ({ image, title, description, cta, ctaLink }) => {
                return (
                  <Card.Root key={`${title}${description}`}>
                    {image && (
                      <Card.ImageWrapper>
                        <div className="lg:tw-hidden">
                          <NextImage
                            src={image.src}
                            width={80}
                            height={80}
                            alt=""
                          />
                        </div>
                        <div className="tw-hidden lg:tw-block">
                          <NextImage
                            src={image.src}
                            width={128}
                            height={128}
                            alt=""
                          />
                        </div>
                      </Card.ImageWrapper>
                    )}
                    <Card.Content>
                      <Card.Title>{title}</Card.Title>
                      <Card.Description>
                        {description && (
                          <Prose>
                            {parseContent(description.__html__, {
                              replaceh4h6TagsWithSmallGreyText: true,
                            })}
                          </Prose>
                        )}
                      </Card.Description>
                    </Card.Content>
                    {cta && ctaLink && (
                      <Card.Cta variant="secondary" asChild>
                        <Link
                          sectionLabel="Card CtaItem"
                          trackingActionLabel={cta}
                          href={ctaLink}
                        >
                          {cta}
                        </Link>
                      </Card.Cta>
                    )}
                  </Card.Root>
                )
              },
            )}
          </CardGrid.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'image-text-feature') {
      component = (
        <ImageTextFeature
          shouldHideFirstTopMargin={shouldHideFirstTopMargin}
          featureImageAlignment={featureImageAlignment[index]}
          featureData={feature}
        />
      )
    } else if (feature.type === 'editorial-carousel-feature') {
      component = (
        <PageAnchor asChild idString={feature.title}>
          <Carousel.Root variant="fullwidth" classNameWrapper="tw-box-border">
            <Carousel.Title>{feature.title}</Carousel.Title>
            <Carousel.Content>
              {feature.cards.map(
                ({ title, description, image, label, link }) => {
                  const accessibilityString = [label, title].join(' ')
                  return (
                    <Link
                      key={link}
                      href={link}
                      aria-label={accessibilityString}
                      trackingActionLabel={accessibilityString}
                      sectionLabel="CardItem"
                    >
                      <EditorialCard.Root>
                        <EditorialCard.ImageWrapper>
                          <NextImage alt="" src={image.src} fill />
                        </EditorialCard.ImageWrapper>
                        <EditorialCard.Content>
                          <EditorialCard.Label>{label}</EditorialCard.Label>
                          <EditorialCard.Title as="h3">
                            {title}
                          </EditorialCard.Title>
                          <EditorialCard.Disclaimer>
                            {description}
                          </EditorialCard.Disclaimer>
                        </EditorialCard.Content>
                      </EditorialCard.Root>
                    </Link>
                  )
                },
              )}
            </Carousel.Content>
            <Carousel.Controls />
          </Carousel.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'carousel-latest-article-feature') {
      component = (
        <PageAnchor asChild idString={feature.title}>
          <Carousel.Root variant="fullwidth" classNameWrapper="tw-box-border">
            <Carousel.Title>{feature.title}</Carousel.Title>
            <Carousel.Content>
              {feature.cards.map(
                ({ title, multiSizeImage, link, label, readingTime }) => {
                  return (
                    <Link
                      key={link}
                      href={link}
                      trackingActionLabel={link}
                      sectionLabel="CardItem"
                      aria-label={`${label}, ${title}, ${readingTime}`}
                    >
                      <EditorialCard.Root>
                        <EditorialCard.ImageWrapper>
                          <MultiSizeImg
                            fill
                            alt=""
                            placeholder="blur"
                            data={multiSizeImage}
                          />
                        </EditorialCard.ImageWrapper>
                        <EditorialCard.Content>
                          <EditorialCard.Label className="tw-capitalize">
                            {label}
                          </EditorialCard.Label>
                          <EditorialCard.Title as={'h3'}>
                            {title}
                          </EditorialCard.Title>
                          <EditorialCard.Disclaimer>
                            {readingTime}
                          </EditorialCard.Disclaimer>
                        </EditorialCard.Content>
                      </EditorialCard.Root>
                    </Link>
                  )
                },
              )}
            </Carousel.Content>
          </Carousel.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'lifestyle-carousel-feature') {
      component = (
        <PageAnchor asChild idString={feature.id}>
          <Carousel.Root variant="background" classNameWrapper="tw-box-border">
            <Carousel.Content>
              {feature.cards.map(({ title, description, multiSizeImage }) => (
                <CardProfile
                  key={title}
                  shape="rounded-sm"
                  className="tw-static tw-bg-white tw-p-6 tw-shadow-popup-card lg:tw-p-8"
                >
                  <Heading type="h2" className="tw-mb-8">
                    {title}
                  </Heading>
                  <Prose>
                    <blockquote className="tw-border-l-primary tw-p-6 tw-font-normal tw-not-italic">
                      {description}
                    </blockquote>
                  </Prose>
                  <Carousel.BackgroundImageWrapper>
                    <MultiSizeImg
                      className="tw-object-cover"
                      width="1920"
                      height="800"
                      alt=""
                      data={multiSizeImage}
                    />
                  </Carousel.BackgroundImageWrapper>
                </CardProfile>
              ))}
            </Carousel.Content>
            <Carousel.Controls />
          </Carousel.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'full-width-feature') {
      component = (
        <PageAnchor asChild idString={feature.title}>
          <FullWidthFeature.Root
            className={cn({
              'tw-pt-4 md:tw-pt-4 lg:tw-pt-4': shouldHideFirstTopMargin,
            })}
          >
            <FullWidthFeature.ImageWrapper parallax={!!isHomepage}>
              <MultiSizeImg fill alt="" data={feature.multiSizeImage} />
            </FullWidthFeature.ImageWrapper>
            <FullWidthFeature.Content shape="cut-sm">
              <FeatureText.Root>
                <FeatureText.Title>{feature.title}</FeatureText.Title>
                <FeatureText.Description>
                  {feature.description && (
                    <Prose>
                      {parseContent(feature.description.__html__, {
                        replaceh4h6TagsWithSmallGreyText: true,
                      })}
                    </Prose>
                  )}
                </FeatureText.Description>
                {feature.cta && (
                  <FeatureText.Cta variant="secondary" asChild>
                    <Link
                      sectionLabel="CtaItem"
                      trackingActionLabel={feature.cta.label}
                      href={feature.cta.to as RelativePathOrHrefExternalType}
                    >
                      {feature.cta.label}
                    </Link>
                  </FeatureText.Cta>
                )}
              </FeatureText.Root>
            </FullWidthFeature.Content>
          </FullWidthFeature.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'logo-tile-feature') {
      component = (
        <PageAnchor
          idString={feature.title}
          asChild
          className="tw-container tw-block"
        >
          <LogoTileGrid.Root>
            <LogoTileGrid.Title
              className={cn({
                'tw-pt-4 lg:tw-pt-4': shouldHideFirstTopMargin,
              })}
            >
              {feature.title}
            </LogoTileGrid.Title>
            {feature.description && (
              <LogoTileGrid.Description>
                <RichText>{feature.description}</RichText>
              </LogoTileGrid.Description>
            )}
            <LogoTileGrid.Content>
              {feature.tileList.map(({ image, label, link }) => {
                const logoTileContent = (
                  <>
                    <LogoTile.ImageWrapper aria-hidden>
                      <NextImage
                        alt={label}
                        width={180}
                        height={80}
                        src={image.src}
                      />
                    </LogoTile.ImageWrapper>
                    <LogoTile.LogoLabel aria-hidden>{label}</LogoTile.LogoLabel>
                  </>
                )
                return link ? (
                  <LogoTile.Root key={label} asChild hasLink>
                    <Link
                      href={link}
                      aria-label={`${label} Logo Tile`}
                      trackingActionLabel={label}
                      sectionLabel="LogoTile"
                    >
                      {logoTileContent}
                    </Link>
                  </LogoTile.Root>
                ) : (
                  <LogoTile.Root key={label}>{logoTileContent}</LogoTile.Root>
                )
              })}
            </LogoTileGrid.Content>
          </LogoTileGrid.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'text-only-feature') {
      component = (
        <PageAnchor idString={feature.title} asChild>
          <FeatureText.Root align="center" className="tw-container">
            <FeatureText.Separator border="transparent" />
            <FeatureText.Title>{feature.title}</FeatureText.Title>
            {feature.description && (
              <FeatureText.Description>
                <RichText>{feature.description}</RichText>
              </FeatureText.Description>
            )}
            {feature.cta?.to && (
              <FeatureText.Cta asChild>
                <Link
                  href={feature.cta.to}
                  trackingActionLabel={feature.title}
                  sectionLabel="TextOnly"
                >
                  {feature.cta.label}
                </Link>
              </FeatureText.Cta>
            )}
            <FeatureText.Separator />
          </FeatureText.Root>
        </PageAnchor>
      )
    } else if (feature.type === 'text-only-styled-feature') {
      component = (
        <Prose
          className={cn(
            'tw-py-16 md:tw-py-14 lg:tw-py-20',
            'prose-h1:tw-font-normal prose-h2:tw-mb-0 prose-h2:tw-mt-16 prose-h3:tw-mb-6 prose-h3:tw-mt-12 prose-p:tw-mt-6 lg:prose-h2:tw-mt-18 lg:prose-h3:tw-mt-14',
            {
              'tw-container': feature.variant === 'text-only_styled',
              'tw-container-article': feature.variant === 'text-only_article',
              'tw-container sm:tw-py-0 md:tw-py-0 lg:tw-py-0':
                feature.variant === 'text-only_header',
            },
            {
              'tw-mt-0 tw-pt-0 md:tw-mt-0 md:tw-pt-0 lg:tw-mt-0 lg:tw-pt-0':
                hideFirstTopMargin,
            },
          )}
          as={feature.variant === 'text-only_article' ? 'article' : undefined}
        >
          {parseContent(feature.description.__html__, { useH1PageTitle: true })}
        </Prose>
      )
    } else if (feature.type === 'promo') {
      component = <Promo feature={feature} />
    } else if (
      feature.type === 'accordion' &&
      feature.variant === 'accordion_default'
    ) {
      component = (
        <PageAnchor idString={feature.title} asChild>
          <section className="tw-container">
            <Heading type="h2" className="tw-mb-6 tw-mt-18">
              {feature.title}
            </Heading>
            <Accordion.Root type={'multiple'}>
              {feature.benefits.map((item) => (
                <Accordion.Item value={item.title} key={item.title}>
                  <Accordion.Trigger>{item.title}</Accordion.Trigger>
                  <Accordion.Content className="tw-flex tw-flex-col tw-gap-4">
                    <Prose className="prose-h4:tw-text-base prose-h4:tw-font-semibold">
                      {parseContent(item.description.__html__, {
                        replaceh4h6TagsWithSmallGreyText: true,
                      })}
                    </Prose>
                  </Accordion.Content>
                </Accordion.Item>
              ))}
            </Accordion.Root>
          </section>
        </PageAnchor>
      )
    } else if (
      feature.type === 'accordion' &&
      feature.variant === 'accordion_faqs'
    ) {
      component = (
        <FaqAccordion
          id={feature.id}
          title={feature.title}
          faqList={feature.faqData}
        />
      )
    } else if (
      feature.type === 'report' &&
      feature.variant === 'availability-and-performance-report'
    ) {
      component = <AvailabilityAndPerformanceReport featureData={feature} />
    } else {
      console.error(
        `ERROR: Feature not implemented. Type:${feature.type} Variant: ${feature.variant}`,
      )
      throw new UnreachableCaseError(feature as never)
    }
    return (
      <FeatureFlagger {...feature} key={feature.id}>
        {component}
      </FeatureFlagger>
    )
  })
}
