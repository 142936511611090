import { Prose } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { forwardRef } from 'react'

import { parseContent } from '@/lib/parse-content'
import type { RichTextType } from '@/types'

type RichTextProps = {
  children: RichTextType
  className?: string
}

const RichText = forwardRef<HTMLDivElement, RichTextProps>(
  ({ children, className }, ref) => {
    if (!children) return null
    return (
      <Prose className={cn(className)} ref={ref}>
        {parseContent(children.__html__)}
      </Prose>
    )
  },
)
RichText.displayName = 'RichText'

export { RichText }
