/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type CookieConsentsArgs } from '../consents'
import { analyticsConfig } from './mparticle/config'

import { AnalyticsEventType } from './constants'
import type {
  ActionEvent,
  AnalyticsConfigProps,
  AnalyticsEvent,
  PageViewEvent,
  PartialScreenEvent,
} from './types'
import {
  initSdk,
  isInitialized,
  setConsents,
  trackEvent,
  trackPageView,
  isTrackableWithoutConsent,
} from './mparticle/helpers'

export const MPARTICLE_INIT_WARNING = 'MParticle SDK must be initialized'

export const isWindowDefined = (): boolean => typeof window !== 'undefined'

interface Analytics {
  init: (props: AnalyticsConfigProps) => void
  setConsents: (consents: CookieConsentsArgs) => void
  trackAction: (event: ActionEvent) => Promise<void>
  trackPartialScreen: (event: PartialScreenEvent) => Promise<void>
  trackPageView: (event: PageViewEvent) => Promise<void>
  _hasAnalyticalConsent: boolean
  _preInitConsents: CookieConsentsArgs | null
  _blockedActionsEvents: ActionEvent[]
  _blockedPartialScreenEvents: PartialScreenEvent[]
  _blockedPageViewEvents: PageViewEvent[]
  _replayBlockedEvents: () => void
  _isTrackingAllowed: (event: AnalyticsEvent) => boolean
}

export const analytics: Analytics = {
  _hasAnalyticalConsent: false,
  _preInitConsents: null,
  _blockedActionsEvents: [],
  _blockedPartialScreenEvents: [],
  _blockedPageViewEvents: [],

  init: function (props) {
    if (!isInitialized()) {
      analyticsConfig
        .setEnvironment(props.environment)
        .setMParticleConfig(props.mParticleConfig)
      initSdk(() => {
        if (this._preInitConsents) {
          this.setConsents(this._preInitConsents)
        }
      })
    }
  },

  setConsents: function (consents) {
    if (isInitialized()) {
      this._hasAnalyticalConsent = consents.Analytical
      setConsents(consents)
      this._replayBlockedEvents()
    } else {
      this._preInitConsents = consents
    }
  },

  _isTrackingAllowed: function (event) {
    return (
      isWindowDefined() &&
      isInitialized() &&
      (this._hasAnalyticalConsent || isTrackableWithoutConsent(event))
    )
  },

  _replayBlockedEvents: function () {
    for (let i = 0; i < this._blockedActionsEvents.length; i++) {
      const currEvent = this._blockedActionsEvents.pop()!
      void this.trackAction(currEvent)
    }
    for (let i = 0; i < this._blockedPartialScreenEvents.length; i++) {
      const currEvent = this._blockedPartialScreenEvents.pop()!
      void this.trackPartialScreen(currEvent)
    }
    for (let i = 0; i < this._blockedPageViewEvents.length; i++) {
      const currEvent = this._blockedPageViewEvents.pop()!
      void this.trackPageView(currEvent)
    }
  },

  trackAction: async function (event) {
    if (!this._isTrackingAllowed(event)) {
      this._blockedActionsEvents.push(event)
    } else {
      const { actionName, interfaceType, sectionName } = event
      await trackEvent({
        mpEventName: AnalyticsEventType.USER_ACTION,
        type: AnalyticsEventType.USER_ACTION,
        eventName: actionName,
        interfaceType,
        pageSectionName: sectionName,
      })
    }
  },

  trackPartialScreen: async function (event) {
    if (!this._isTrackingAllowed(event)) {
      this._blockedPartialScreenEvents.push(event)
    } else {
      const { partialScreenName, interfaceType, backgroundPageTitle } = event
      await trackEvent({
        mpEventName: AnalyticsEventType.PARTIAL_SCREEN,
        type: AnalyticsEventType.PARTIAL_SCREEN,
        eventName: partialScreenName,
        interfaceType,
        backgroundPageTitle,
        partialScreenTitle: partialScreenName,
      })
    }
  },

  trackPageView: async function (event) {
    if (!this._isTrackingAllowed(event)) {
      this._blockedPageViewEvents.push(event)
    } else {
      await trackPageView(event)
    }
  },
}
