import { Heading } from '@chaseweb/ui-library/src'

import { DocumentDetails, FaqAccordion } from '@/components'
import type { FeatureAvailabilityAndPerformanceReport } from '@/data/aem/main-pages/pageTransform/availability-and-performance-report'

import { Report } from './report.client'

/* istanbul ignore next - no need to test it */
const sortAsc = (a: string, b: string) => (a < b ? 1 : -1)

export const AvailabilityAndPerformanceReport = ({
  featureData,
}: {
  featureData: FeatureAvailabilityAndPerformanceReport
}) => {
  const yearList = Object.keys(featureData.data).sort(sortAsc)

  // TODO: Add history accordion to AEM feature
  const faqHistoryList = [
    {
      title: 'Availability and performance data',
      content: (
        <div>
          {yearList.map((year: string) =>
            Object.keys(featureData.data[Number(year)])
              .sort(sortAsc)
              .map((quarter: string) => (
                <div key={`${quarter}/${year}`}>
                  <Heading type="h3" className="tw-my-0">
                    {`Q${quarter} ${year}`}
                  </Heading>
                  <DocumentDetails
                    data={{
                      pdfUrl:
                        featureData.data[Number(year)][Number(quarter)]
                          .reportUrl,
                    }}
                  />
                </div>
              )),
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <article className="tw-container tw-mb-14 md:tw-mb-20 lg:tw-mb-16">
        <Report featureData={featureData} />
      </article>
      <FaqAccordion
        title={featureData.accordion.title}
        faqList={featureData.accordion.items}
        id="calculations-of-metrics-accordion"
        dataTestId="calculations-of-metrics-accordion"
      />
      <FaqAccordion
        title="Historical data"
        faqList={faqHistoryList}
        id="historical-data-accordion"
        dataTestId="historical-data-accordion"
      />
      <div className="tw-mb-14 lg:tw-mb-16" />
    </>
  )
}
