'use client'
import { cn } from '@chaseweb/utils/cn'
import { useSplitStore } from '@chaseweb/utils/split'
import type { ReactNode } from 'react'

export const FeatureFlagger = ({
  children,
  featureFlag,
}: {
  children: ReactNode
  featureFlag?: {
    key: string
    treatment: string
    isControl: boolean
  } | null
}): ReactNode => {
  const client = useSplitStore((state) => state.clients.user)
  const treatment = featureFlag ? client?.getTreatment(featureFlag.key) : null

  const shouldShowComponent =
    !featureFlag || // show if there is no feature flag
    treatment === featureFlag.treatment || // show if the feature flag treatment matches for this feature
    (treatment === 'control' && featureFlag.isControl) // show if split is in CONTROL mode and the feature is marked as control

  return (
    <div
      className={cn({
        'tw-hidden': !shouldShowComponent,
        'ui-no-js-display-block': featureFlag?.isControl, // show when JS is disabled if the feature is marked as control
      })}
    >
      {children}
    </div>
  )
}
