'use client'
import { create } from 'zustand'
import { SplitFactory } from '@splitsoftware/splitio-browserjs'
import {
  initLocalhostSplitConfig,
  verifySplitQueryParam,
} from './localhostSplit'

export enum SplitTrafficTypes {
  'user' = 'user',
  'channel_type' = 'channel_type',
}

interface SplitConsentsProps {
  consentsCaptured: boolean
  hasConsentToSendImpressions: boolean
}

interface SplitInitializerProps extends SplitConsentsProps {
  apiKey: string
  proxyUrl?: string | null
  webProspectId: string
  marketingChannel: string
}

const proxyUrlOf = (proxyUrl: string, api: string) =>
  proxyUrl.replace(/\/$/, '').replace(/^http:/, 'https:') + '/' + api

interface SplitState {
  _factory: SplitIO.ISDK | undefined
  clients: Record<SplitTrafficTypes, SplitIO.IClient | undefined>
  init: (args: SplitInitializerProps) => Promise<void>
  updateCookieConsents: (args: SplitConsentsProps) => void
  reset: () => void // needed for unit tests, not ideal, but could be worse
}

const initialState = {
  _factory: undefined,
  clients: {
    user: undefined,
    channel_type: undefined,
  },
}

export const useSplitStore = create<SplitState>((set, get) => ({
  ...initialState,
  reset: () => {
    set(initialState)
  },
  init: async ({
    apiKey,
    proxyUrl = null,
    webProspectId,
    marketingChannel,
    consentsCaptured,
    hasConsentToSendImpressions,
  }) => {
    if (get()._factory) return

    const userConsent = consentsCaptured
      ? hasConsentToSendImpressions
        ? 'GRANTED'
        : 'DECLINED'
      : 'UNKNOWN'

    const urls: SplitIO.UrlSettings = {}
    if (proxyUrl !== null) {
      urls.sdk = proxyUrlOf(proxyUrl, 'splitio/sdk')
      urls.events = proxyUrlOf(proxyUrl, 'splitio/events')
    }

    let factory: SplitIO.ISDK

    const queryParams = new URLSearchParams(window.location.search)
    if (verifySplitQueryParam(queryParams)) {
      factory = initLocalhostSplitConfig(
        queryParams,
        {
          key: webProspectId,
        },
        {
          urls,
          userConsent,
        },
      )
    } else {
      factory = SplitFactory({
        core: {
          authorizationKey: apiKey,
          key: webProspectId, // default - we override when we instatiate the clients for readability
        },
        urls,
        userConsent,
        debug: false,
        streamingEnabled: false,
        sync: { enabled: false },
      })
    }

    set({ _factory: factory })

    const userClient = factory.client(webProspectId)
    const channelTypeClient = factory.client(marketingChannel)

    await userClient.ready()
    await channelTypeClient.ready()

    set({
      clients: {
        user: userClient,
        channel_type: channelTypeClient,
      },
    })
  },
  updateCookieConsents: (args) => {
    const { _factory } = get()
    if (args.consentsCaptured && _factory) {
      _factory.UserConsent.setStatus(args.hasConsentToSendImpressions)
    }
  },
}))
