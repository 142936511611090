import type { URLSearchParams } from 'url'
import {
  SplitFactory,
  LocalhostFromObject,
} from '@splitsoftware/splitio-browserjs'

export const verifySplitQueryParam = (queryParams: URLSearchParams) => {
  return queryParams.get('split') !== null
}

export const initLocalhostSplitConfig = (
  queryParams: URLSearchParams,
  settings: {
    key: string
  },
  splitProps: { urls?: any; userConsent?: any } | undefined = {},
) => {
  const splitParam = queryParams.get('split')
  let features = {
    download_cta_slide_panel: 'on',
    home_banner: {
      treatment: 'on',
      config: JSON.stringify({
        variant: 'information',
        title: 'Home banner news',
        titleUrl: '',
        content: 'DEV TEST: Home banner content',
      }),
    },
    product_banner: {
      treatment: 'on',
      config: JSON.stringify({
        variant: 'information',
        title: 'Product banner news',
        titleUrl: '',
        content: 'DEV TEST: Product banner content',
      }),
    },
    homepage_feature_label: 'on',
    homepage_hero_cta_size: 'on',
    homepage_hero_cta_text: 'on',
    homepage_invert_feature_image_text: 'on',
    support_banner: {
      treatment: 'on',
      config: JSON.stringify({
        variant: 'warning',
        title: 'Trouble using the app',
        content:
          'DEV TEST: Some customers are having trouble using the app, completing online card payment checks and logging in.',
        titleUrl: 'https://chaseuk.statuspage.io/incidents/srmgdyb6rrg5',
      }),
    },
    trustpilot_widgets: 'on',
  }

  const parseJSONStringData = (data: string) => {
    try {
      return JSON.parse(data)
    } catch {
      return data
    }
  }

  // We are correcting split because object will also contain commas so we don't want to split to happen in that case
  // Example page_redirects={"source": "/sourcePage", redirect: "/redirectPage"},home_banner=on
  // It shouldn't split like 'page_redirects={"source": "/sourcePage"','"redirect: "/redirectPage"}', 'home_banner=on'
  // Rather it should be 'page_redirects={"source": "/sourcePage", redirect: "/redirectPage"}', 'home_banner=on'
  const getCorrectedSplitArray = (splittedEncodedParam: any[]) => {
    return splittedEncodedParam.reduce(
      (accumulator: string | number, currentValue: string) => {
        return (
          accumulator +
          (currentValue.startsWith('%22') || // starts with "
          currentValue.startsWith('%5C%22') || // starts with \"
          currentValue.startsWith('%7B%5C%22') // starts with {\"
            ? '%2C'
            : ',') +
          currentValue
        )
      },
    )
  }

  if (splitParam) {
    const encodedParam = encodeURIComponent(splitParam)
    const splittedEncodedParam = encodedParam.split('%2C') // check separation by comma
    const correctedSplit = getCorrectedSplitArray(splittedEncodedParam)
    const splitMap = correctedSplit
      .split(',')
      .reduce((map: any, paramString: string) => {
        const [key, value] = decodeURIComponent(paramString).split('=')
        return {
          [key]: value ? parseJSONStringData(value.replaceAll("'", '')) : value,
          ...map,
        }
      }, {})
    features = { ...features, ...splitMap }
  }

  return SplitFactory({
    core: {
      authorizationKey: 'localhost',
      key: settings.key,
    },
    features,
    sync: {
      localhostMode: LocalhostFromObject(),
    },
    ...splitProps,
  })
}
