'use client'

import { SVG } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { usePathname } from 'next/navigation'

import { Link } from '@/components'

export function Logo(props: { className?: string }) {
  const pathname = usePathname()
  const isHomepage = pathname === '/'

  return (
    <Link
      {...props}
      notTracked
      href="/"
      className={cn(props.className, 'tw-h-full')}
      aria-label={isHomepage ? 'Chase logo' : 'Chase home page'}
    >
      <SVG name="ChaseLogo" className="tw-h-full" />
    </Link>
  )
}
