import { Promo as PromoUI } from '@chaseweb/ui-library/src/molecules'
import { cn } from '@chaseweb/utils/cn'

import type { FeaturePromoData } from '@/data/aem/features'

import { HeroCassLogo } from '../../app/(default-layout)/(main-pages)/_components/hero/hero-cass-logo'
import { DownloadLink } from '../download-link'
import { Link } from '../link'
import { OptimisedImg } from '../optimised-img'
import { RichText } from '../rich-text'

export const Promo = ({ feature }: { feature: FeaturePromoData }) => {
  return (
    <section
      className={cn('tw-relative tw-mb-12 tw-mt-20 lg:tw-mb-16 lg:tw-mt-24', {
        'tw-container': ['secondary', 'tertiary'].includes(feature.variant),
        'tw-container-maxwidth tw-px-2 md:tw-mt-16 md:tw-px-0 lg:tw-mt-24':
          feature.variant === 'primary',
      })}
    >
      <PromoUI.Root variant={feature.variant}>
        <PromoUI.ContentWrapper>
          <PromoUI.Title as="h2" type={feature.titleType}>
            {feature.title}
          </PromoUI.Title>
          <PromoUI.Description>
            <RichText>{feature.description}</RichText>
          </PromoUI.Description>
          {feature.cta && (
            <PromoUI.Cta asChild noExternalIcon>
              {feature.cta.to ? (
                <Link
                  sectionLabel="promo"
                  trackingActionLabel={feature.cta.label}
                  href={feature.cta.to}
                >
                  {feature.cta.label}
                </Link>
              ) : (
                <DownloadLink sectionLabel="promo">
                  {feature.cta.label}
                </DownloadLink>
              )}
            </PromoUI.Cta>
          )}
        </PromoUI.ContentWrapper>
        <PromoUI.ImageWrapper asChild variant={feature.image.variant}>
          <OptimisedImg
            data={feature.image.data}
            alt=""
            width={600}
            height={600}
          />
        </PromoUI.ImageWrapper>
      </PromoUI.Root>
      <div className="tw-absolute tw-bottom-0 tw-right-0 tw-p-4">
        {feature.description.__html__ && (
          <HeroCassLogo description={feature.description.__html__} />
        )}
      </div>
    </section>
  )
}
