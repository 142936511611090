import { getUtmParameters, getUtmParametersFromCookie } from '../tracking'
import { SEARCH_ENGINES } from '../tracking/vendor'

export function getMarketingChannel() {
  const utmParams = getUtmParametersFromCookie() ?? getUtmParameters()
  if (utmParams?.utm_medium) return utmParams.utm_medium
  if (typeof window !== 'undefined' && document?.referrer) {
    for (const engine in SEARCH_ENGINES) {
      if (document.referrer.match(new RegExp(engine, 'i')))
        return 'organic_search'
    }
    return 'organic_website'
  }
  return 'direct'
}
