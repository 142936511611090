import fscsSVG from '@chaseweb/assets/resources/FSCS/FSCS_logo.svg'
import { Footer as FooterUI, SVG } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'

import {
  AppStoreButton,
  BbaLogo,
  ConsentModalLink,
  Link,
  NextImage,
} from '@/components'
import { socialMediaLinks } from '@/data/externalLinkUrls'
import type { RelativePathOrHrefExternalType } from '@/types'

const SECTION_LABEL = 'Footer'

const footerLinkList: {
  columnHeader: string
  linkList: (
    | { label: string; url: RelativePathOrHrefExternalType; component?: never }
    | { label: string; url?: never; component: JSX.Element }
  )[]
}[] = [
  {
    columnHeader: 'Company',
    linkList: [
      {
        label: 'Newsroom',
        url: '/media/',
      },
      {
        label: 'Updates from Chase',
        url: '/updates-from-chase/',
      },
      {
        label: 'Careers',
        url: '/careers-at-chase/',
      },
      {
        label: 'Sitemap',
        url: '/sitemap/',
      },
    ],
  },
  {
    columnHeader: 'Legal',
    linkList: [
      {
        label: 'All legal documents',
        url: '/legal/',
      },
      {
        label: 'General Account T&Cs',
        url: '/legal/general-terms-and-conditions/',
      },
      {
        label: 'Round-up T&Cs',
        url: '/legal/round-ups/',
      },
      {
        label: 'Manage settings',
        component: <ConsentModalLink />,
      },
      {
        label: 'Info for TPPS',
        url: '/information-for-tpps/',
      },
      {
        label: 'Privacy Policy',
        url: '/legal/privacy-notice/',
      },
      {
        label: 'Cookie Policy',
        url: '/legal/cookie-policy/',
      },
    ],
  },
  {
    columnHeader: 'Support',
    linkList: [
      {
        label: 'Help centre',
        url: '/support/',
      },
      {
        label: 'Service status',
        url: 'https://chaseuk.statuspage.io/',
      },
      {
        label: 'Contact us',
        url: '/support/contact-us/',
      },
      {
        label: 'Accessibility',
        url: '/support/accessibility/',
      },
      {
        label: 'Here for all our customers',
        url: '/how-we-help/',
      },
    ],
  },
]

export function Footer({
  showDisclaimerOnly = false,
}: {
  showDisclaimerOnly?: boolean
}) {
  return (
    <FooterUI.Root className="tw-flex-shrink-0">
      <FooterUI.Container>
        {!showDisclaimerOnly && (
          <>
            <FooterUI.LinksContainer>
              {footerLinkList.map((footerLinkColumn) => (
                <FooterUI.LinksColumn key={footerLinkColumn.columnHeader}>
                  <FooterUI.ColumnHeader>
                    {footerLinkColumn.columnHeader}
                  </FooterUI.ColumnHeader>
                  <FooterUI.LinkList>
                    {footerLinkColumn.linkList.map((link) => {
                      if (link.component) {
                        return (
                          <FooterUI.Link asChild key={link.label}>
                            {link.component}
                          </FooterUI.Link>
                        )
                      } else {
                        return (
                          <FooterUI.Link asChild key={link.label}>
                            <Link
                              href={link.url}
                              trackingActionLabel={link.label}
                              sectionLabel={SECTION_LABEL}
                              resetFocusAfterClick
                            >
                              {link.label}
                            </Link>
                          </FooterUI.Link>
                        )
                      }
                    })}
                  </FooterUI.LinkList>
                </FooterUI.LinksColumn>
              ))}
              <FooterUI.LinksColumn>
                <FooterUI.ColumnHeader>Follow us</FooterUI.ColumnHeader>
                <FooterUI.LinkList className="footer-with-links__social-media-links tw-flex tw-flex-row tw-gap-8">
                  <FooterUI.Link asChild noExternalIcon>
                    <Link
                      className="tw-flex"
                      trackingActionLabel="YouTube"
                      sectionLabel={SECTION_LABEL}
                      href={socialMediaLinks.youTubeLink}
                    >
                      <SVG name="YouTube" aria-label="YouTube" />
                    </Link>
                  </FooterUI.Link>
                  <FooterUI.Link asChild noExternalIcon>
                    <Link
                      className="tw-flex"
                      trackingActionLabel="Facebook"
                      sectionLabel={SECTION_LABEL}
                      href={socialMediaLinks.facebookLink}
                    >
                      <SVG name="Facebook" aria-label="Facebook" />
                    </Link>
                  </FooterUI.Link>
                  <FooterUI.Link asChild noExternalIcon>
                    <Link
                      className="tw-flex"
                      trackingActionLabel="Instagram"
                      sectionLabel={SECTION_LABEL}
                      href={socialMediaLinks.instagramLink}
                    >
                      <SVG name="Instagram" aria-label="Instagram" />
                    </Link>
                  </FooterUI.Link>
                  <FooterUI.Link asChild noExternalIcon>
                    <Link
                      className="tw-flex"
                      trackingActionLabel="X"
                      sectionLabel={SECTION_LABEL}
                      href={socialMediaLinks.xLink}
                    >
                      <SVG name="X" aria-label="X" />
                    </Link>
                  </FooterUI.Link>
                  <FooterUI.Link asChild noExternalIcon>
                    <Link
                      className="tw-flex"
                      trackingActionLabel="LinkedIn"
                      sectionLabel={SECTION_LABEL}
                      href={socialMediaLinks.linkedInLink}
                    >
                      <SVG name="LinkedIn" aria-label="LinkedIn" />
                    </Link>
                  </FooterUI.Link>
                </FooterUI.LinkList>
                <FooterUI.ColumnHeader>Download our app</FooterUI.ColumnHeader>
                <div className="tw-flex tw-flex-row tw-gap-4">
                  <AppStoreButton store="apple" />
                  <AppStoreButton store="google" />
                </div>
                <div className="tw-mt-12 tw-flex tw-gap-4">
                  <BbaLogo />
                  <NextImage
                    src={fscsSVG}
                    alt="FSCS protected"
                    className={cn(
                      'hero__fscs-image tw-h-14 tw-invert lg:tw-h-16',
                      'tw-brightness-[99%] tw-contrast-[88%] tw-hue-rotate-[181deg] tw-invert-[35%] tw-saturate-[488%] tw-sepia-[21%]',
                    )}
                  />
                </div>
              </FooterUI.LinksColumn>
            </FooterUI.LinksContainer>
            <FooterUI.Separator />
          </>
        )}
        <FooterUI.Disclaimer>
          <p>
            Chase is a registered trademark and trading name of J.P. Morgan
            Europe Limited.
          </p>
          <p>
            J.P. Morgan Europe Limited is authorised by the Prudential
            Regulation Authority and regulated by the Financial Conduct
            Authority and the Prudential Regulation Authority. Our Financial
            Services Register number is 124579. Registered in England & Wales
            with company number 938937. Our registered office is 25 Bank Street,
            Canary Wharf, London, E14 5JP, United Kingdom.
          </p>
          <p>
            Nutmeg Saving and Investment Limited is authorised and regulated by
            the FCA in relation to certain investment services and restricted
            advice only. Chase is a trading name of J.P. Morgan Europe Limited.
            Nutmeg and J.P. Morgan Europe Limited are J.P. Morgan companies.
            Products provided by Nutmeg are not guaranteed by Chase.
          </p>
        </FooterUI.Disclaimer>
      </FooterUI.Container>
    </FooterUI.Root>
  )
}
