'use client'
import { QRCode } from '@chaseweb/ui-library/src'
import { cn } from '@chaseweb/utils/cn'
import { useAttributionUrlLink } from '@chaseweb/utils/tracking/vendor'

import { useChaseEnv } from '@/lib/hooks'

export const DownloadQrCode = ({ className }: { className?: string }) => {
  const env = useChaseEnv()
  const attributionDownloadLink = useAttributionUrlLink({
    appsflyerLink: env?.openAppUrl ?? '',
    variant: 'download',
    sitePrefix: process.env.NEXT_PUBLIC_BASE_PATH
      ? process.env.NEXT_PUBLIC_BASE_PATH + '/'
      : '',
  })

  if (!env?.openAppUrl || !attributionDownloadLink) return null

  return (
    <div
      data-testid="download-qr-code"
      data-link={attributionDownloadLink}
      className={cn(
        'tw-aspect-square tw-w-4/5 [&>canvas]:!tw-h-full [&>canvas]:!tw-w-full [&>canvas]:tw-rounded-2xl',
        className,
      )}
      aria-label="qr code to download chase app"
      role="link"
    >
      <QRCode value={attributionDownloadLink} />
    </div>
  )
}
